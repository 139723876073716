import styled from '@emotion/styled';
import { Box, Flex } from '@qga/roo-ui/components';
import { themeGet } from 'styled-system';
import { rem } from 'polished';
import { css } from '@emotion/core';
import { mediaQuery } from 'lib/styledSystem';
import InputWithAddon from 'components/InputWithAddon';

export const ResultList = styled(Box)`
  display: none;
  position: absolute;
  width: 100%;
  border-left: ${themeGet('borders.1')} ${themeGet('colors.greys.alto')};
  border-right: ${themeGet('borders.1')} ${themeGet('colors.greys.alto')};
  box-shadow: ${themeGet('shadows.result')};
  z-index: ${themeGet('zIndices.searchControls')};
  max-height: ${rem('150px')};
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  transform: translate3d(0, 0, 0); //fixes a safari bug with zindex clashing with sticky campaign messaging
  cursor: pointer;
  ${({ isOpen }) =>
    isOpen &&
    css`
      display: block;
    `};

  ${mediaQuery.minWidth.sm} {
    max-height: ${rem('400px')};
  }
`;
ResultList.displayName = 'ResultList';

export const UpdatedResultList = styled(ResultList)`
  margin-top: ${({ hasItems }) => (hasItems ? '0.625rem' : '0')};

  border: ${({ hasItems, ...props }) => (hasItems ? `${themeGet('borders.1')(props)} ${themeGet('colors.greys.alto')(props)}` : 'none')};
  border-radius: 0.625rem;

  max-height: ${rem('197px')} !important;

  ${mediaQuery.minWidth.sm} {
    max-height: ${rem('246px')} !important;
  }
`;
UpdatedResultList.displayName = 'UpdatedResultList';

export const ResultItem = styled(Flex)`
  flex-direction: row;
  align-items: end;
  background-color: ${themeGet('colors.greys.porcelain')};
  border-bottom: ${themeGet('borders.1')} ${themeGet('colors.greys.alto')};
  padding: ${themeGet('space.3')} ${themeGet('space.4')};
  cursor: pointer;
  ${(props) =>
    props.highlighted &&
    css`
      background-color: white;
    `};
`;
ResultItem.displayName = 'ResultItem';

export const UpdatedResultItem = styled(ResultItem)`
  align-items: flex-start;
  gap: 0.625rem;

  padding: ${themeGet('space.3')};
`;
UpdatedResultItem.displayName = 'UpdatedResultItem';

export const AutocompleteInput = styled(InputWithAddon)(
  (props) =>
    !props.isFocused && {
      '::placeholder': {
        opacity: 1, // Firefox's default placeholder opacity is less that 1
        color: props.theme.colors.greys.charcoal,
      },
    },
);

AutocompleteInput.displayName = 'AutocompleteInput';

export const UpdatedAutocompleteInput = styled(AutocompleteInput)((props) => ({
  fontSize: props.theme.fontSizes.sm,
  lineHeight: 1.5,
  padding: '12px',
  paddingLeft: '44px',
  '&::placeholder': {
    color: props.theme.colors.greys.steel,
  },
}));

UpdatedAutocompleteInput.displayName = 'UpdatedAutocompleteInput';
