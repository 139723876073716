import { useEffect, useRef, useState } from 'react';
import { searchLocations } from 'lib/clients/searchLocations';
import { searchProperties } from 'lib/clients/searchProperties';
import get from 'lodash/get';

const MIN_SEARCH_LENGTH = 3;
const DEBOUNCE_TIMEOUT = 400;

/* this could be extracted if it is useful in other components */
const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [delay, value]);

  return debouncedValue;
};

const propertyFullName = (property) => {
  const fullName = [property.name, get(property, 'address.suburb'), get(property, 'address.country')];
  return fullName.filter((item) => item).join(', ');
};

export const useLocationData = ({ locationName, returnProperties = true }) => {
  const previousValue = useRef();
  const debouncedLocationName = useDebounce(locationName, DEBOUNCE_TIMEOUT);
  const [results, setResults] = useState([]);

  useEffect(() => {
    /* no change, don't fetch */
    if (previousValue.current === debouncedLocationName) return;

    previousValue.current = debouncedLocationName;

    /* less than 3 characters, reset results and don't fetch */
    if (!debouncedLocationName || debouncedLocationName.length < MIN_SEARCH_LENGTH) {
      setResults([]);
      return;
    }

    (async () => {
      try {
        /* This would benefit from using react-query for caching and ordering responses */
        const [locations, properties] = await Promise.all([
          searchLocations({ name: debouncedLocationName }),
          returnProperties ? searchProperties({ name: debouncedLocationName }) : Promise.resolve([]),
        ]);

        /* map name to fullName on property as the presence of the name attribute causes issues with downshift */
        const mappedProperties = properties.map((property) => ({
          id: property.id,
          fullName: propertyFullName(property),
          type: 'property',
        }));
        setResults([...locations, ...mappedProperties]);
      } catch (e) {
        setResults([]);
      }
    })();
  }, [debouncedLocationName, returnProperties]);

  return results;
};
